define("discourse/plugins/discourse-wcr-theme/discourse/initializers/discourse-wcr-theme", ["exports", "virtual-dom", "ember-addons/ember-computed-decorators", "discourse/lib/url", "discourse/lib/plugin-api"], function (_exports, _virtualDom, _emberComputedDecorators, _url, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-wcr-theme",
    initialize(container) {
      (0, _pluginApi.withPluginApi)('0.8.8', api => {
        api.createWidget('wcrsearch', {
          tagName: 'div.wcrsearch',
          html() {
            return "";
          }
        });
      });
    }
  };
});